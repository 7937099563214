import { mapMutations } from 'vuex'
import axiosInstance from '../api/axios'
import { ENDPOINTS } from '../const/endpoints.const'
import { ErrorHandler } from '../class/error-handler.class'
import { Raven } from 'vue-raven'

export default {
  props: {
    hash: {
      type: String,
      required: false
    }
  },
  methods: {
    ...mapMutations([
      'SET_AGREEMENTS',
      'SET_AGREEMENTS_LOADING',
      'SET_HASH_ERROR',
      'SET_INTEGRATOR_NAME'
    ]),
    getAgreements () {
      if (!this.$route.params.hash) {
        return
      }
      this.SET_AGREEMENTS_LOADING(true)
      axiosInstance.post(ENDPOINTS.validateHash, { hash: this.$route.params.hash })
        .then(response => {
          this.SET_AGREEMENTS(response.data)
          this.SET_INTEGRATOR_NAME(response.data.integratorName)
        })
        .catch(error => {
          this.SET_HASH_ERROR(true)
          ErrorHandler.handleError(error)
          Raven.captureMessage('Main.vue getAgreements error', {
            extra: error
          })
        })
        .finally(() => {
          this.SET_AGREEMENTS_LOADING(false)
        })
    }
  },
  created () {
    this.getAgreements()
  }
}
