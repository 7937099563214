<template>
  <footer class="footer">
    <div class="footer-container">
      <h3 class="section-secondary-title">Żeby uzyskać więcej informacji – skontaktuj się z <a class="title-link" href="https://www.brutto.pl/" title="Przejdź do strony brutto.pl">brutto.pl</a></h3>
      <div class="footer-info"><img class="text-image" src="@/assets/images/call_black.svg" alt=""><a class="phone-link" href="tel:226999747" title="Zadzwoń pod ten numer">22 699 97 47</a></div>
      <p class="article-text">Zespół brutto.pl jest do&nbsp;Twojej dyspozycji: <br>
        od poniedziałku do piątku – w&nbsp;godzinach 9:00&#8209;17:00</p>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer'
}
</script>
