<template>
  <div class="initial-loader">
    <div class="loader">
      <svg width="262" height="178" viewBox="0 0 262 178" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="money">
          <path d="M105 122.544H123.975V120.971H106.601V99.3591H123.789V97.7617H105V122.544Z"/>
          <path d="M110.765 117.749C110.976 117.748 111.179 117.664 111.329 117.514C111.479 117.365 111.563 117.162 111.564 116.951V112.047C111.563 111.836 111.479 111.633 111.329 111.483C111.179 111.333 110.977 111.249 110.765 111.248C110.659 111.246 110.553 111.266 110.455 111.305C110.357 111.345 110.269 111.403 110.194 111.478C110.12 111.552 110.06 111.641 110.02 111.739C109.981 111.837 109.962 111.942 109.964 112.047V116.951C109.962 117.056 109.981 117.161 110.02 117.258C110.06 117.356 110.12 117.445 110.194 117.52C110.269 117.594 110.359 117.653 110.457 117.692C110.555 117.732 110.659 117.751 110.765 117.749Z"/>
          <path d="M27 122.544H51.3937V120.971H28.6013V99.3591H51.2336V97.7617H27V122.544Z"/>
          <path d="M32.7661 117.615C32.9739 117.616 33.1733 117.536 33.3228 117.392C33.4723 117.247 33.5603 117.051 33.5668 116.843V111.913C33.5658 111.702 33.4803 111.499 33.3304 111.349C33.1805 111.2 32.9781 111.115 32.7661 111.114C32.6605 111.112 32.5545 111.132 32.4565 111.171C32.3586 111.21 32.2705 111.269 32.1958 111.344C32.121 111.418 32.0614 111.507 32.0219 111.605C31.9823 111.703 31.9636 111.808 31.9655 111.913V116.843C31.9665 116.947 31.9886 117.049 32.0295 117.144C32.0704 117.239 32.1289 117.325 32.2034 117.397C32.2779 117.469 32.3662 117.525 32.4626 117.563C32.5591 117.6 32.6626 117.618 32.7661 117.615Z"/>
          <path d="M157.72 117.216H156.01V94.5375H110V92.8335H157.72V117.216Z"/>
          <path d="M147.285 109.248C147.059 109.247 146.842 109.156 146.682 108.997C146.522 108.837 146.432 108.622 146.431 108.396V103.119C146.432 102.893 146.522 102.676 146.682 102.516C146.842 102.357 147.058 102.266 147.285 102.265C147.397 102.264 147.51 102.285 147.614 102.328C147.718 102.37 147.812 102.432 147.892 102.512C147.971 102.591 148.035 102.686 148.078 102.791C148.12 102.895 148.14 103.006 148.139 103.119V108.396C148.14 108.508 148.119 108.619 148.076 108.723C148.034 108.827 147.971 108.922 147.892 109.001C147.812 109.081 147.717 109.144 147.613 109.186C147.508 109.229 147.397 109.249 147.285 109.248Z"/>
          <path d="M75.3055 122.678H50.3155V120.974H73.5684V99.522H50.5V97.8157H75.3055V122.678Z"/>
          <path d="M80.0854 117.216H78.3773V94.5375H32.5V92.8335H80.0854V117.216Z"/>
          <path d="M152.943 122.678H123.5V120.974H151.235V99.522H123.686V97.8157L152.943 97.8157V122.678Z"/>
          <path d="M69.6471 109.248C69.5345 109.25 69.4236 109.228 69.3192 109.186C69.2149 109.144 69.1198 109.081 69.0401 109.002C68.9605 108.922 68.898 108.828 68.8556 108.724C68.8132 108.62 68.7915 108.508 68.793 108.396V103.119C68.793 102.892 68.883 102.676 69.0432 102.516C69.2034 102.356 69.4205 102.266 69.6471 102.266C69.8737 102.266 70.0908 102.356 70.251 102.516C70.4112 102.676 70.5011 102.892 70.5011 103.119V108.396C70.5027 108.508 70.4825 108.62 70.4401 108.724C70.3978 108.828 70.3337 108.922 70.2541 109.002C70.1744 109.081 70.0809 109.144 69.9765 109.186C69.8722 109.228 69.7597 109.25 69.6471 109.248Z"/>
          <circle cx="129.5" cy="109.5" r="5.75" stroke-width="1.5"/>
          <circle cx="49.5" cy="109.5" r="5.75" stroke-width="1.5"/>
        </g>
        <g id="other">
          <path d="M56.8976 130.06C56.6325 130.057 56.3688 130.106 56.1228 130.205C55.8769 130.304 55.6528 130.452 55.464 130.637C55.2752 130.823 55.1258 131.043 55.0232 131.288C54.9207 131.532 54.8682 131.793 54.8677 132.058C54.8677 132.592 55.0801 133.103 55.4579 133.48C55.8357 133.858 56.348 134.07 56.8823 134.07C57.4166 134.07 57.9289 133.858 58.3068 133.48C58.6846 133.103 58.897 132.592 58.897 132.058C58.8972 131.796 58.8448 131.536 58.7445 131.294C58.6441 131.051 58.497 130.832 58.3113 130.646C58.1257 130.461 57.9058 130.313 57.6632 130.212C57.4205 130.112 57.1603 130.06 56.8976 130.06ZM56.8976 135.79C56.1576 135.796 55.4321 135.582 54.8143 135.175C54.1965 134.769 53.7148 134.187 53.428 133.506C53.1412 132.825 53.0627 132.074 53.2038 131.348C53.3449 130.623 53.6982 129.957 54.2195 129.433C54.7409 128.908 55.4066 128.55 56.132 128.403C56.8574 128.257 57.6103 128.329 58.2946 128.611C58.9788 128.892 59.5633 129.37 59.9752 129.984C60.3871 130.598 60.6078 131.319 60.6081 132.058C60.6053 133.042 60.2137 133.987 59.5192 134.685C58.8247 135.383 57.8832 135.781 56.8976 135.79Z"/>
          <path d="M66.0801 65.9457H49.0267C48.8003 65.9444 48.5842 65.8535 48.4242 65.6937C48.2643 65.5338 48.1736 65.3174 48.1726 65.0914C48.1712 64.979 48.1927 64.868 48.2351 64.764C48.2776 64.6599 48.3401 64.5647 48.4197 64.4853C48.4993 64.4059 48.5945 64.3434 48.6988 64.3011C48.803 64.2588 48.9141 64.2379 49.0267 64.2394H66.0801C66.193 64.2374 66.3048 64.2582 66.4095 64.3003C66.5143 64.3424 66.6101 64.4051 66.6902 64.4846C66.7703 64.564 66.8334 64.6581 66.8762 64.7625C66.919 64.8668 66.94 64.9787 66.9388 65.0914C66.9377 65.3182 66.8481 65.5352 66.6871 65.6952C66.5261 65.8552 66.3073 65.9457 66.0801 65.9457Z"/>

          <path d="M188.928 40.6812C185.112 40.6812 181.455 42.195 178.757 44.8887C176.059 47.5824 174.543 51.2356 174.543 55.045C174.543 58.8544 176.059 62.5076 178.757 65.2012C181.455 67.8949 185.112 69.408 188.928 69.408C191.49 69.408 196.881 69.5145 202.592 69.6478C209.265 69.7819 216.124 69.9409 219.914 69.9409C224.528 69.9471 229.048 68.6388 232.943 66.1697C236.838 63.7007 239.946 60.1729 241.903 56.0013C243.86 51.8297 244.585 47.1874 243.992 42.619C243.399 38.0506 241.513 33.7459 238.555 30.2105C235.598 26.6751 231.691 24.0556 227.294 22.6597C222.897 21.2639 218.194 21.1495 213.735 22.3301C209.275 23.5106 205.245 25.9376 202.118 29.325C198.991 32.7125 196.898 36.9199 196.083 41.4541C196.067 41.5731 196.021 41.6862 195.951 41.7837C195.88 41.8813 195.787 41.9601 195.679 42.0137C195.452 42.1159 195.194 42.1255 194.959 42.0403C193.075 41.1447 191.014 40.6802 188.928 40.6812ZM219.914 71.5132C216.124 71.5132 209.237 71.3799 202.566 71.2201C196.854 71.1133 191.462 70.9802 188.928 70.9802C186.58 70.983 184.262 70.4688 182.136 69.4742C180.011 68.4797 178.132 67.0292 176.632 65.2264C175.133 63.4235 174.049 61.3125 173.46 59.0438C172.871 56.7752 172.79 54.4048 173.224 52.1014C173.657 49.7981 174.594 47.6184 175.967 45.7179C177.341 43.8174 179.117 42.2434 181.169 41.1069C183.222 39.9703 185.501 39.2998 187.843 39.1432C190.185 38.9866 192.532 39.347 194.718 40.2C195.764 35.452 198.133 31.0959 201.552 27.6347C204.971 24.1735 209.301 21.7483 214.041 20.639C218.781 19.5297 223.74 19.7822 228.343 21.3661C232.946 22.9501 237.006 25.801 240.056 29.5907C243.106 33.3805 245.019 37.9547 245.577 42.7842C246.134 47.6137 245.313 52.502 243.207 56.8853C241.101 61.2685 237.796 64.968 233.675 67.5562C229.554 70.1445 224.783 71.5156 219.914 71.5125V71.5132Z"/>
          <path d="M254.794 58.6427C253.686 58.6428 252.603 58.9711 251.681 59.5861C250.76 60.201 250.042 61.0755 249.618 62.0979C249.194 63.1203 249.083 64.2446 249.299 65.33C249.515 66.4154 250.048 67.4126 250.832 68.1952C251.616 68.9777 252.615 69.5104 253.702 69.7263C254.789 69.9423 255.916 69.8315 256.94 69.4081C257.964 68.9846 258.839 68.2678 259.455 67.3477C260.071 66.4276 260.399 65.3456 260.399 64.2389C260.399 63.504 260.255 62.7769 259.973 62.0979C259.692 61.4189 259.278 60.8017 258.758 60.282C258.237 59.7623 257.62 59.3495 256.94 59.0683C256.26 58.7871 255.53 58.6426 254.794 58.6427ZM254.794 71.4296C253.369 71.4294 251.976 71.0074 250.791 70.2167C249.606 69.426 248.682 68.3023 248.137 66.9876C247.592 65.6729 247.45 64.226 247.728 62.8304C248.006 61.4347 248.692 60.1528 249.7 59.1467C250.708 58.1406 251.993 57.4552 253.391 57.1778C254.789 56.9003 256.236 57.0435 257.553 57.5882C258.869 58.1329 259.996 59.0545 260.788 60.2378C261.579 61.4211 262 62.8121 262 64.2351C262 66.1435 261.241 67.974 259.889 69.3236C258.538 70.6731 256.705 71.4318 254.794 71.4326V71.4296Z"/>
          <g id="signal">
            <path id="first" d="M174.2 33.887C174.067 33.8862 173.934 33.851 173.818 33.7857C173.701 33.7204 173.603 33.6267 173.532 33.5131C170.363 28.7941 166.122 24.8887 161.155 22.115C156.188 19.3413 150.636 17.7779 144.949 17.5512C144.842 17.5466 144.737 17.5206 144.64 17.4751C144.542 17.4296 144.456 17.3657 144.384 17.2863C144.311 17.2069 144.255 17.114 144.219 17.0129C144.183 16.9119 144.167 16.8048 144.173 16.6977C144.186 16.48 144.283 16.2753 144.443 16.1266C144.603 15.9779 144.815 15.8962 145.033 15.8982C150.976 16.1453 156.776 17.7867 161.965 20.6897C167.153 23.5927 171.585 27.6754 174.899 32.6071C174.959 32.6956 175 32.7959 175.021 32.901C175.041 33.0061 175.04 33.1138 175.018 33.2185C174.995 33.3231 174.952 33.422 174.89 33.5093C174.828 33.5967 174.748 33.6711 174.656 33.7271C174.527 33.8306 174.366 33.8871 174.2 33.887Z"/>
            <path id="second" d="M183.402 33.9121C183.247 33.9142 183.095 33.8733 182.962 33.7933C182.83 33.7134 182.724 33.5975 182.654 33.4598C179.03 26.5823 173.667 20.7707 167.098 16.601C160.53 12.4313 152.985 10.049 145.21 9.68979C144.142 9.63677 143.078 9.63662 142.009 9.6639C141.793 9.6614 141.583 9.57748 141.425 9.42939C141.267 9.28131 141.172 9.07953 141.155 8.86368C141.154 8.64617 141.235 8.43682 141.384 8.27816C141.533 8.1195 141.737 8.02388 141.954 8.01167C143.077 7.98439 144.197 8.01197 145.29 8.06421C153.353 8.4357 161.179 10.9057 167.991 15.2297C174.802 19.5538 180.363 25.581 184.121 32.7137C184.172 32.8078 184.202 32.9112 184.211 33.0175C184.221 33.1237 184.21 33.231 184.178 33.3327C184.146 33.4344 184.093 33.5286 184.024 33.6098C183.955 33.691 183.869 33.7575 183.774 33.8055C183.657 33.8619 183.531 33.8978 183.402 33.9121Z"/>
            <path id="third" d="M168.445 8.66967C156.852 2.9226 146.402 2.62406 142.701 3.17142C142.373 3.2199 142.068 2.99346 142.019 2.66565C141.971 2.33785 142.197 2.03281 142.525 1.98433C146.472 1.40066 157.169 1.74035 168.978 7.59453C180.813 13.4618 188.97 23.9251 191.587 28.4523C191.753 28.7392 191.655 29.1062 191.368 29.272C191.081 29.4379 190.714 29.3398 190.548 29.0529C188.013 24.667 180.012 14.4037 168.445 8.66967Z"/>
          </g>
          <path d="M104.779 56.9625C104.56 56.9619 104.35 56.875 104.195 56.7204C104.04 56.5657 103.952 56.3553 103.951 56.1364V52.8852C103.952 52.6662 104.04 52.4568 104.195 52.302C104.35 52.1472 104.56 52.0599 104.779 52.0591C104.886 52.0603 104.992 52.083 105.091 52.1253C105.189 52.1676 105.279 52.2289 105.353 52.3058C105.427 52.3827 105.485 52.4735 105.524 52.573C105.563 52.6726 105.581 52.7784 105.579 52.8852V56.1364C105.581 56.2431 105.563 56.3498 105.524 56.4493C105.485 56.5488 105.427 56.6397 105.353 56.7165C105.279 56.7934 105.189 56.8548 105.091 56.897C104.992 56.9392 104.886 56.9614 104.779 56.9625Z"/>
          <path d="M109.21 56.9625C109.103 56.9614 108.997 56.9392 108.899 56.897C108.8 56.8548 108.711 56.7933 108.637 56.7165C108.562 56.6397 108.503 56.5488 108.464 56.4493C108.425 56.3498 108.407 56.2432 108.409 56.1364V52.8852C108.407 52.7783 108.425 52.6726 108.464 52.573C108.503 52.4735 108.562 52.3826 108.637 52.3058C108.711 52.2289 108.8 52.1676 108.899 52.1253C108.997 52.083 109.103 52.0603 109.21 52.0591C109.429 52.0599 109.639 52.1471 109.794 52.302C109.949 52.4568 110.036 52.6663 110.037 52.8852V56.1364C110.036 56.3551 109.949 56.5649 109.794 56.7196C109.639 56.8743 109.429 56.9617 109.21 56.9625Z"/>
          <path d="M113.668 56.9625C113.449 56.9618 113.239 56.875 113.084 56.7203C112.929 56.5657 112.841 56.3553 112.84 56.1363V52.8852C112.837 52.7746 112.855 52.6648 112.895 52.5616C112.935 52.4584 112.996 52.3639 113.073 52.2844C113.15 52.205 113.243 52.1418 113.345 52.0987C113.447 52.0555 113.556 52.0339 113.667 52.0339C113.777 52.0339 113.888 52.0555 113.99 52.0987C114.092 52.1418 114.184 52.205 114.261 52.2844C114.339 52.3639 114.4 52.4584 114.44 52.5616C114.48 52.6648 114.498 52.7746 114.495 52.8852V56.1363C114.494 56.3549 114.407 56.5642 114.252 56.7188C114.098 56.8734 113.887 56.9612 113.668 56.9625Z"/>
          <path d="M118.125 56.9625C117.906 56.9619 117.697 56.875 117.541 56.7204C117.386 56.5657 117.298 56.3553 117.297 56.1364V52.8852C117.298 52.6662 117.386 52.4568 117.541 52.302C117.697 52.1472 117.906 52.0599 118.125 52.0591C118.232 52.0603 118.338 52.083 118.437 52.1253C118.535 52.1676 118.625 52.2289 118.699 52.3058C118.773 52.3827 118.831 52.4735 118.87 52.573C118.909 52.6726 118.927 52.7784 118.925 52.8852V56.1364C118.927 56.2431 118.909 56.3498 118.87 56.4493C118.831 56.5488 118.773 56.6397 118.699 56.7165C118.625 56.7934 118.535 56.8548 118.437 56.897C118.338 56.9392 118.232 56.9614 118.125 56.9625Z"/>
          <path d="M126.05 56.9627C125.831 56.9614 125.622 56.8744 125.467 56.7198C125.313 56.5652 125.225 56.3552 125.223 56.1366V52.8854C125.22 52.7749 125.238 52.6649 125.278 52.5618C125.318 52.4587 125.38 52.3648 125.457 52.2854C125.534 52.206 125.626 52.1427 125.728 52.0996C125.83 52.0565 125.939 52.0349 126.05 52.0349C126.161 52.0349 126.27 52.0565 126.372 52.0996C126.474 52.1427 126.566 52.206 126.643 52.2854C126.72 52.3648 126.782 52.4587 126.822 52.5618C126.862 52.6649 126.88 52.7749 126.877 52.8854V56.1366C126.876 56.3551 126.789 56.5651 126.634 56.7198C126.479 56.8744 126.269 56.9616 126.05 56.9627Z"/>
          <path d="M130.183 56.9625C129.964 56.9611 129.756 56.8735 129.602 56.7189C129.447 56.5643 129.36 56.3547 129.359 56.1364V52.8852C129.366 52.6703 129.456 52.4669 129.611 52.3172C129.765 52.1676 129.972 52.0835 130.187 52.0835C130.402 52.0835 130.609 52.1676 130.764 52.3172C130.918 52.4669 131.009 52.6703 131.015 52.8852V56.1364C131.013 56.3557 130.924 56.5658 130.768 56.7204C130.612 56.875 130.402 56.9619 130.183 56.9625Z"/>
          <path d="M104.993 28.6893C103.132 28.6914 101.348 29.4304 100.032 30.7443C98.7161 32.0582 97.9755 33.8398 97.9732 35.698V62.2136C97.9757 64.0716 98.7161 65.8528 100.032 67.1665C101.348 68.4802 103.132 69.2194 104.993 69.2215H151.511C153.374 69.22 155.16 68.4819 156.48 67.1687C157.8 65.8556 158.546 64.074 158.554 62.2136V35.698C158.546 33.8374 157.801 32.0554 156.481 30.742C155.162 29.4287 153.375 28.6907 151.511 28.6893H104.993ZM151.511 70.8737H104.993C102.694 70.8696 100.49 69.9552 98.8638 68.3322C97.238 66.7091 96.323 64.5092 96.3185 62.2136V35.698C96.3239 33.405 97.2405 31.2073 98.8669 29.5885C100.493 27.9697 102.697 27.0619 104.993 27.0637H151.511C153.807 27.0627 156.009 27.9713 157.634 29.59C159.26 31.2088 160.176 33.4055 160.181 35.698V62.2136C160.177 64.5086 159.263 66.7083 157.638 68.3314C156.012 69.9545 153.81 70.869 151.511 70.8737Z"/>
          <path d="M144.732 55.6578H150.07V47.8771H144.732V55.6578ZM150.897 57.2842H143.93C143.711 57.2827 143.501 57.1954 143.346 57.0405C143.191 56.8857 143.104 56.6761 143.103 56.4573V47.0502C143.104 46.8315 143.193 46.6225 143.347 46.4678C143.502 46.313 143.711 46.2254 143.93 46.2241H150.897C151.116 46.2249 151.327 46.3122 151.482 46.467C151.637 46.6218 151.725 46.8312 151.726 47.0502V56.4573C151.725 56.6765 151.636 56.8863 151.481 57.0413C151.325 57.1962 151.116 57.2833 150.897 57.2842Z"/>
          <path d="M107.422 144.13H100.483C100.256 144.13 100.037 144.04 99.8763 143.88C99.7154 143.72 99.6244 143.504 99.6232 143.277C99.6293 143.052 99.7225 142.837 99.8824 142.679C100.042 142.52 100.258 142.429 100.483 142.424H107.422C107.648 142.426 107.864 142.517 108.023 142.677C108.183 142.837 108.272 143.052 108.273 143.277C108.275 143.389 108.254 143.502 108.212 143.606C108.17 143.71 108.107 143.804 108.028 143.883C107.949 143.963 107.855 144.027 107.75 144.069C107.646 144.111 107.535 144.131 107.422 144.13Z"/>
          <path d="M123.059 144.13H116.121C115.895 144.129 115.679 144.038 115.519 143.879C115.359 143.719 115.268 143.503 115.267 143.277C115.273 143.053 115.364 142.839 115.523 142.68C115.682 142.521 115.897 142.43 116.121 142.424H123.059C123.285 142.426 123.501 142.516 123.661 142.676C123.821 142.835 123.912 143.051 123.913 143.277C123.914 143.39 123.894 143.502 123.852 143.606C123.81 143.71 123.747 143.805 123.667 143.885C123.588 143.964 123.493 144.027 123.388 144.069C123.284 144.111 123.171 144.131 123.059 144.13Z"/>
          <path d="M138.698 144.13H131.759C131.647 144.131 131.534 144.111 131.43 144.069C131.326 144.027 131.232 143.963 131.152 143.883C131.073 143.804 131.01 143.71 130.968 143.606C130.926 143.502 130.905 143.389 130.907 143.277C130.908 143.052 130.997 142.835 131.157 142.676C131.316 142.516 131.533 142.426 131.759 142.424H138.698C138.923 142.43 139.136 142.521 139.295 142.68C139.454 142.839 139.546 143.053 139.552 143.277C139.551 143.503 139.461 143.719 139.301 143.879C139.141 144.038 138.925 144.129 138.698 144.13Z"/>
          <path d="M154.34 144.13H147.401C147.288 144.131 147.176 144.111 147.072 144.069C146.967 144.027 146.872 143.964 146.793 143.885C146.713 143.805 146.65 143.71 146.608 143.606C146.566 143.502 146.544 143.39 146.545 143.277C146.547 143.051 146.637 142.835 146.797 142.676C146.957 142.516 147.175 142.426 147.401 142.424H154.34C154.565 142.43 154.779 142.521 154.938 142.68C155.097 142.839 155.188 143.053 155.194 143.277C155.193 143.503 155.103 143.719 154.943 143.879C154.783 144.038 154.566 144.129 154.34 144.13Z"/>
          <path d="M169.952 144.13H163.039C162.926 144.132 162.814 144.111 162.71 144.069C162.605 144.027 162.51 143.964 162.431 143.885C162.351 143.805 162.288 143.71 162.246 143.606C162.204 143.502 162.182 143.39 162.183 143.277C162.185 143.051 162.275 142.835 162.435 142.676C162.595 142.516 162.813 142.426 163.039 142.424H169.952C170.181 142.425 170.401 142.513 170.565 142.673C170.729 142.832 170.825 143.049 170.832 143.277C170.831 143.391 170.807 143.503 170.762 143.608C170.717 143.712 170.652 143.807 170.57 143.886C170.488 143.965 170.391 144.029 170.285 144.07C170.179 144.112 170.066 144.132 169.952 144.13Z"/>
          <path d="M185.191 144.13H178.281C178.168 144.132 178.055 144.111 177.95 144.069C177.845 144.027 177.75 143.964 177.669 143.885C177.589 143.805 177.526 143.71 177.483 143.606C177.441 143.502 177.419 143.39 177.421 143.277C177.422 143.05 177.513 142.834 177.674 142.674C177.835 142.514 178.054 142.424 178.281 142.424H185.191C185.418 142.424 185.636 142.514 185.797 142.674C185.958 142.834 186.049 143.05 186.05 143.277C186.051 143.39 186.03 143.501 185.987 143.606C185.945 143.711 185.881 143.807 185.801 143.886C185.721 143.966 185.625 144.028 185.521 144.07C185.416 144.112 185.304 144.132 185.191 144.13Z"/>
          <path d="M104.886 151.246H97.9471C97.7171 151.246 97.4967 151.156 97.334 150.993C97.1714 150.831 97.0793 150.611 97.0793 150.381C97.0793 150.151 97.1714 149.931 97.334 149.769C97.4967 149.607 97.7171 149.515 97.9471 149.515H104.886C105.112 149.521 105.325 149.615 105.483 149.777C105.64 149.938 105.728 150.156 105.728 150.381C105.728 150.606 105.64 150.823 105.483 150.984C105.325 151.146 105.112 151.239 104.886 151.246Z"/>
          <path d="M121.193 151.246H114.254C114.024 151.246 113.803 151.156 113.64 150.993C113.478 150.831 113.386 150.611 113.386 150.381C113.386 150.151 113.478 149.931 113.64 149.769C113.803 149.607 114.024 149.515 114.254 149.515H121.193C121.418 149.521 121.632 149.615 121.789 149.777C121.946 149.938 122.034 150.156 122.034 150.381C122.034 150.606 121.946 150.823 121.789 150.984C121.632 151.146 121.418 151.239 121.193 151.246Z"/>
          <path d="M137.82 151.246H130.026C129.8 151.239 129.586 151.146 129.429 150.984C129.272 150.823 129.184 150.606 129.184 150.381C129.184 150.156 129.272 149.938 129.429 149.777C129.586 149.615 129.8 149.521 130.026 149.515H137.82C138.046 149.521 138.26 149.615 138.417 149.777C138.574 149.938 138.662 150.156 138.662 150.381C138.662 150.606 138.574 150.823 138.417 150.984C138.26 151.146 138.046 151.239 137.82 151.246Z"/>
          <path d="M155.271 151.246H147.48C147.25 151.246 147.029 151.156 146.867 150.993C146.704 150.831 146.612 150.611 146.612 150.381C146.612 150.151 146.704 149.931 146.867 149.769C147.029 149.607 147.25 149.515 147.48 149.515H155.271C155.501 149.515 155.722 149.607 155.885 149.769C156.047 149.931 156.138 150.151 156.138 150.381C156.138 150.611 156.047 150.831 155.885 150.993C155.722 151.156 155.501 151.246 155.271 151.246Z"/>
          <path d="M171.821 151.246H164.909C164.679 151.246 164.459 151.156 164.296 150.993C164.133 150.831 164.041 150.611 164.041 150.381C164.041 150.151 164.133 149.931 164.296 149.769C164.459 149.607 164.679 149.515 164.909 149.515H171.821C172.051 149.515 172.271 149.607 172.434 149.769C172.596 149.931 172.687 150.151 172.687 150.381C172.687 150.611 172.596 150.831 172.434 150.993C172.271 151.156 172.051 151.246 171.821 151.246Z"/>
          <path d="M187.727 151.246H180.816C180.586 151.246 180.365 151.156 180.203 150.993C180.04 150.831 179.948 150.611 179.948 150.381C179.948 150.151 180.04 149.931 180.203 149.769C180.365 149.607 180.586 149.515 180.816 149.515H187.727C187.953 149.521 188.167 149.615 188.324 149.777C188.481 149.938 188.569 150.156 188.569 150.381C188.569 150.606 188.481 150.823 188.324 150.984C188.167 151.146 187.953 151.239 187.727 151.246Z"/>
          <path d="M102.616 158.335H95.6772C95.4525 158.329 95.2384 158.238 95.0794 158.079C94.9204 157.92 94.8274 157.706 94.8217 157.482C94.8229 157.256 94.9134 157.04 95.0733 156.88C95.2332 156.721 95.451 156.63 95.6772 156.629H102.616C102.729 156.628 102.84 156.649 102.944 156.692C103.049 156.734 103.144 156.796 103.223 156.876C103.303 156.955 103.365 157.05 103.408 157.155C103.45 157.259 103.472 157.37 103.47 157.482C103.469 157.708 103.379 157.924 103.219 158.083C103.059 158.243 102.843 158.333 102.616 158.335Z"/>
          <path d="M119.59 158.335H112.65C112.426 158.329 112.213 158.238 112.054 158.079C111.895 157.92 111.804 157.706 111.798 157.482C111.799 157.256 111.89 157.04 112.049 156.881C112.209 156.721 112.424 156.631 112.65 156.629H119.59C119.702 156.627 119.816 156.648 119.92 156.69C120.025 156.732 120.119 156.795 120.2 156.874C120.28 156.954 120.343 157.049 120.386 157.153C120.428 157.258 120.45 157.369 120.448 157.482C120.447 157.709 120.356 157.925 120.195 158.085C120.034 158.245 119.817 158.335 119.59 158.335Z"/>
          <path d="M137.473 158.335H128.826C128.6 158.334 128.383 158.243 128.223 158.083C128.063 157.924 127.973 157.708 127.972 157.482C127.97 157.37 127.992 157.259 128.034 157.155C128.077 157.05 128.139 156.955 128.219 156.876C128.298 156.796 128.393 156.734 128.498 156.692C128.602 156.649 128.713 156.628 128.826 156.629H137.473C137.585 156.628 137.697 156.649 137.801 156.692C137.905 156.734 138 156.796 138.08 156.876C138.159 156.955 138.222 157.05 138.264 157.155C138.307 157.259 138.328 157.37 138.327 157.482C138.326 157.708 138.235 157.924 138.075 158.083C137.915 158.243 137.699 158.334 137.473 158.335Z"/>
          <path d="M156.874 158.335H148.232C148.005 158.335 147.787 158.245 147.626 158.085C147.465 157.925 147.374 157.709 147.373 157.482C147.372 157.369 147.393 157.258 147.436 157.153C147.478 157.049 147.542 156.954 147.622 156.874C147.702 156.795 147.797 156.732 147.902 156.69C148.007 156.648 148.119 156.627 148.232 156.629H156.877C157.104 156.631 157.32 156.721 157.48 156.881C157.64 157.04 157.73 157.256 157.731 157.482C157.726 157.707 157.633 157.922 157.474 158.081C157.314 158.239 157.1 158.33 156.874 158.335Z"/>
          <path d="M173.556 158.335H166.643C166.416 158.334 166.199 158.243 166.039 158.084C165.879 157.924 165.788 157.708 165.787 157.482C165.785 157.37 165.807 157.259 165.85 157.155C165.892 157.05 165.954 156.955 166.034 156.876C166.114 156.796 166.209 156.734 166.313 156.692C166.418 156.649 166.53 156.628 166.643 156.629H173.556C173.67 156.627 173.782 156.648 173.888 156.69C173.994 156.732 174.092 156.794 174.173 156.873C174.255 156.952 174.321 157.047 174.366 157.152C174.411 157.256 174.434 157.368 174.436 157.482C174.429 157.71 174.333 157.927 174.169 158.087C174.005 158.246 173.785 158.334 173.556 158.335Z"/>
          <path d="M190.129 158.335H183.216C182.987 158.334 182.767 158.246 182.603 158.087C182.439 157.927 182.342 157.71 182.335 157.482C182.336 157.368 182.361 157.256 182.406 157.152C182.451 157.047 182.517 156.952 182.598 156.873C182.68 156.794 182.776 156.732 182.882 156.69C182.988 156.648 183.102 156.627 183.216 156.629H190.129C190.242 156.628 190.353 156.649 190.457 156.692C190.561 156.734 190.657 156.796 190.736 156.876C190.816 156.955 190.878 157.05 190.921 157.155C190.963 157.259 190.985 157.37 190.983 157.482C190.982 157.708 190.892 157.924 190.732 158.084C190.572 158.243 190.355 158.334 190.129 158.335Z"/>
          <path d="M96.3993 134.403H189.704V89.6343C189.701 87.2388 188.747 84.9422 187.05 83.2485C185.354 81.5548 183.053 80.6024 180.654 80.6004H105.446C103.048 80.6033 100.749 81.5564 99.053 83.25C97.357 84.9436 96.402 87.2393 96.3993 89.6343V134.403ZM190.558 136.109H95.5453C95.4327 136.111 95.3217 136.09 95.2174 136.048C95.113 136.005 95.0179 135.942 94.9383 135.863C94.8587 135.783 94.7961 135.689 94.7538 135.585C94.7114 135.48 94.6897 135.369 94.6912 135.256V89.6336C94.6888 88.2214 94.9649 86.823 95.5041 85.5175C96.0434 84.2119 96.8357 83.0248 97.8344 82.0249C98.8332 81.0251 100.019 80.2316 101.325 79.6897C102.631 79.1479 104.032 78.8687 105.446 78.8674H180.654C183.509 78.8745 186.244 80.0119 188.26 82.0303C190.276 84.0487 191.408 86.7829 191.407 89.6336V135.256C191.409 135.369 191.389 135.48 191.346 135.584C191.304 135.688 191.243 135.782 191.163 135.862C191.084 135.941 190.99 136.004 190.886 136.047C190.782 136.089 190.67 136.11 190.558 136.109Z"/>
          <path d="M173.928 129.552H108.114C106.281 129.552 104.524 128.825 103.228 127.53C101.932 126.236 101.203 124.481 101.203 122.65V92.321C101.203 90.4906 101.932 88.7354 103.228 87.4412C104.524 86.1469 106.281 85.4199 108.114 85.4197H177.852C179.684 85.4244 181.44 86.1532 182.735 87.4465C184.03 88.7398 184.76 90.492 184.765 92.321V115.186C184.765 115.416 184.673 115.637 184.51 115.799C184.348 115.962 184.127 116.053 183.897 116.053C183.667 116.053 183.445 115.962 183.283 115.799C183.12 115.637 183.029 115.416 183.029 115.186V92.321C183.029 90.9478 182.483 89.6307 181.514 88.6571C180.544 87.6836 179.227 87.1326 177.852 87.1252H108.114C106.737 87.1326 105.418 87.6828 104.443 88.6556C103.469 89.6284 102.918 90.9453 102.911 92.321V122.65C102.912 124.028 103.462 125.349 104.437 126.323C105.413 127.297 106.735 127.845 108.114 127.847H173.928C174.154 127.847 174.371 127.937 174.532 128.097C174.692 128.257 174.782 128.474 174.782 128.7C174.782 128.926 174.692 129.143 174.532 129.303C174.371 129.463 174.154 129.552 173.928 129.552Z"/>
          <path d="M199.415 170.273H85.5618C84.0975 170.27 82.6944 169.689 81.6591 168.655C80.6238 167.621 80.0404 166.218 80.038 164.756C80.0414 163.616 80.3848 162.504 81.0247 161.56L94.7672 134.859C94.8725 134.66 95.0515 134.51 95.2659 134.441C95.4803 134.371 95.7146 134.387 95.9171 134.486C96.1196 134.594 96.2713 134.776 96.3411 134.995C96.4109 135.213 96.393 135.45 96.2908 135.656L82.5177 162.411C82.492 162.438 82.4917 162.489 82.4659 162.519C81.988 163.167 81.7353 163.952 81.7461 164.756C81.7459 165.257 81.8441 165.751 82.0358 166.214C82.2275 166.676 82.5081 167.098 82.8624 167.452C83.2168 167.806 83.6377 168.085 84.1008 168.277C84.5639 168.469 85.0606 168.568 85.5618 168.568H199.415C200.12 168.571 200.813 168.379 201.416 168.014C202.019 167.648 202.508 167.124 202.832 166.498C203.155 165.873 203.298 165.169 203.246 164.467C203.195 163.765 202.95 163.091 202.539 162.519C202.503 162.481 202.477 162.435 202.461 162.385L189.543 135.63C189.444 135.425 189.43 135.188 189.505 134.973C189.58 134.759 189.738 134.582 189.943 134.483C190.148 134.384 190.385 134.372 190.6 134.447C190.816 134.522 190.992 134.679 191.091 134.884L203.956 161.586C204.619 162.51 204.973 163.619 204.97 164.756C204.965 166.223 204.378 167.628 203.336 168.662C202.295 169.697 200.884 170.276 199.415 170.273Z"/>
          <path d="M204.298 178H79.9293C79.8169 178.002 79.7055 177.982 79.6014 177.939C79.4972 177.897 79.4033 177.834 79.3238 177.755C79.2443 177.676 79.1816 177.58 79.1393 177.476C79.0969 177.373 79.0753 177.261 79.0767 177.149C79.0767 176.923 79.1671 176.705 79.3268 176.545C79.4866 176.384 79.7025 176.293 79.9293 176.292H204.298C204.526 176.292 204.744 176.382 204.905 176.543C205.066 176.704 205.157 176.922 205.157 177.149C205.158 177.262 205.137 177.374 205.094 177.478C205.052 177.582 204.988 177.677 204.908 177.757C204.828 177.836 204.732 177.897 204.628 177.939C204.523 177.981 204.411 178.002 204.298 178Z"/>
          <rect x="87.5" y="89" width="5.5" height="36" fill="white"/>
          <rect x="21.7" y="89" width="6" height="36" fill="white"/>
          <path d="M39.312 57.3379C36.6691 57.3441 34.1369 58.397 32.2707 60.2655C30.4045 62.134 29.3567 64.6649 29.3578 67.3038V131.315C29.3571 133.954 30.4045 136.485 32.2707 138.353C34.1368 140.221 36.6694 141.273 39.312 141.28H75.9018C78.5446 141.274 81.0766 140.222 82.9431 138.354C84.8096 136.485 85.8581 133.954 85.8575 131.315V67.3038C85.8583 64.6647 84.8111 62.1332 82.9446 60.2647C81.0781 58.3962 78.5448 57.3439 75.9018 57.3379H39.312ZM75.9018 142.987H39.312C36.2156 142.982 33.2482 141.749 31.0613 139.56C28.8744 137.372 27.6474 134.406 27.6497 131.314V67.3031C27.647 64.2112 28.8743 61.2452 31.0613 59.0564C33.2482 56.8676 36.2155 55.6354 39.312 55.6309H75.9018C79.001 55.6342 81.9718 56.865 84.1631 59.0533C86.3545 61.2417 87.5871 64.2085 87.59 67.3031V131.314C87.5865 134.409 86.3544 137.375 84.1631 139.563C81.9719 141.751 79.0007 142.982 75.9018 142.986V142.987Z"/>
        </g>
      </svg>
    </div>
  </div>
</template>
<script>
export default {
  name: 'InitialLoader'
}
</script>

<style lang="scss">
@import "../assets/scss/mixins";

.initial-loader {
  .loader {
    padding: 10px 0 30px;
    margin: 0 auto;

    @include md {
      padding: 30px 0;
    }

    svg {
      fill: #333;

      circle {
        fill: none;
        stroke: #333;
      }

      #money {
        transform: translate(-20px, 0px);
        animation: moving 10s linear infinite;
      }

      #other {
        fill: #333;

        #signal {

          #first {
            opacity: 0;
            animation: signal 1s infinite;
          }

          #second {
            opacity: 0;
            animation: signal 1s infinite;
            animation-delay: .2s;
          }

          #third {
            opacity: 0;
            animation: signal 1s infinite;
            animation-delay: .4s;
          }
        }
      }
    }
  }

  @keyframes moving {
    0% {
      transform: translateX(-20px);
    }
    50% {
      transform: translateX(20px);
    }
    100% {
      transform: translateX(-20px);
    }
  }

  @keyframes signal {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}

</style>
