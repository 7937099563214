import Vue from 'vue'
import VueRaven from 'vue-raven'
import App from './App.vue'
import store from './vuex/store'
import router from './router'

import '@babel/polyfill'

import { Form, FormItem, Input, Checkbox, Button } from 'element-ui'
import lang from 'element-ui/lib/locale/lang/pl'
import locale from 'element-ui/lib/locale'
locale.use(lang)

if (process.env.VUE_APP_MODE === 'development' && process.env.VUE_APP_USE_MOCK === 'true') {
  require('./mock/browser')
}

if (process.env.VUE_APP_MODE === 'production') {
  Vue.use(VueRaven, {
    dsn: process.env.VUE_APP_SENTRY,
    environment: process.env.NODE_ENV
  })
}

Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(Checkbox)
Vue.use(Button)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
