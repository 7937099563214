import Vue from 'vue'
import Vuex from 'vuex'
import scenarios from './modules/scenarios'
import agreements from './modules/agreements'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    scenarios,
    agreements
  }
})

export default store
