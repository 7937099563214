import Main from '../components/Main'

export const ROUTING = {
  home: '/',
  start: '/start',
  shoper: '/shoper'
}

export const routes = [
  {
    path: ROUTING.home,
    alias: '',
    name: 'Main',
    component: Main
  },
  {
    path: ROUTING.start + '/:hash?',
    name: 'Start',
    component: Main,
    props: true
  },
  {
    path: ROUTING.shoper,
    name: 'ShoperLanding',
    component: () => import('../layouts/shoper-lp')
  },
  {
    path: '*',
    redirect: ROUTING.home
  }]
