import { Notification } from 'element-ui'
import { ERROR } from '../const/error.const'

export class ErrorHandler {
  static handleError (error) {
    const { status, data } = error.response

    if (status >= 500) {
      Notification.error(ERROR.INTERNAL_SERVER_ERROR)
      return
    }

    if (!Object.keys(data.fieldErrors).length) {
      return
    }

    Object.keys(data.fieldErrors).forEach(key => {
      Notification.error({
        title: ERROR.DEFAULT_ERROR.title,
        message: data.fieldErrors[key]
      })
    })
  }
}
