<template>
  <main class="main" :class="[layout, {'is-loading': isLoading}]">
    <InitialLoader v-if="isLoading" />
    <component v-bind:is="layout" />
  </main>
</template>

<script>
import { PARTNER, PARTNERS } from '../const/partners.const'
import MainMixin from '../mixins/main.mixin'
import { mapState } from 'vuex'
import InitialLoader from './InitialLoader'

export default {
  name: 'Main',
  mixins: [MainMixin],
  components: {
    InitialLoader,
    'default-layout': () => import('../layouts/default'),
    [PARTNER.skyShop]: () => import('../layouts/sky-shop'),
    [PARTNER.shoper]: () => import('../layouts/shoper'),
    [PARTNER.atomStore]: () => import('../layouts/atom-store')
  },
  computed: {
    ...mapState({
      integratorName: state => state.agreements.integratorName,
      isLoading: state => state.agreements.isLoading
    }),
    layout () {
      return PARTNERS.includes(this.integratorName) ? this.integratorName : 'default-layout'
    }
  }
}
</script>
