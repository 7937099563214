export const PARTNER = {
  skyShop: 'SKY_SHOP',
  shoper: 'Shoper',
  atomStore: 'AtomStore'
}

export const PARTNERS = [
  PARTNER.skyShop,
  PARTNER.shoper,
  PARTNER.atomStore
]
