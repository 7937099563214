export const ERROR = {
  INTERNAL_SERVER_ERROR: {
    dangerouslyUseHTMLString: true,
    title: 'Przepraszamy.',
    message: "Usługa chwilowo niedostępna. Spróbuj ponownie za chwilę lub skontaktuj się z&nbsp;<a href='mailto:brutto@brutto.pl'>brutto@brutto.pl</a>"
  },
  DEFAULT_ERROR: {
    title: 'Wystąpił błąd.'
  }
}
