const state = {
  scenario: 2,
  amount: null,
  percent: null
}

const mutations = {
  SET_SCENARIO_ACTIVE (state, value) {
    state.scenario = value
  },
  SET_SCENARIO_AMOUNT (state, value) {
    state.amount = value
  },
  SET_SCENARIO_PERCENT (state, value) {
    state.percent = value
  }
}

export default {
  state,
  mutations
}
