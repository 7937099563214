const state = {
  dialog: false,
  data: null,
  integratorName: null,
  isLoading: false,
  isProcessing: false,
  isHashError: false
}

const mutations = {
  SET_DIALOG_ACTIVE (state, value) {
    state.dialog = value
  },
  SET_AGREEMENTS (state, agreements) {
    state.data = agreements
  },
  SET_AGREEMENTS_LOADING (state, value) {
    state.isLoading = value
  },
  SET_AGREEMENTS_PROCESSING (state, value) {
    state.isProcessing = value
  },
  SET_HASH_ERROR (state, value) {
    state.isHashError = value
  },
  SET_INTEGRATOR_NAME (state, value) {
    state.integratorName = value
  }
}

const getters = {
  getRequiredConsentList (state) {
    const filtered = state.data?.consentList?.filter(consent => consent.required && consent.showCheckbox)
    filtered.forEach(consent => {
      consent.id = '' + consent.id
      consent.checked = false
    })
    return filtered
  },
  getConsentList (state) {
    const filtered = state.data?.consentList?.filter(consent => !consent.required && consent.showCheckbox)
    filtered.forEach(consent => {
      consent.id = '' + consent.id
      consent.checked = false
    })
    return filtered
  },
  getTextIfNoCheckbox (state) {
    return state.data?.consentList?.filter(consent => !consent.showCheckbox)
  }
}

export default {
  state,
  mutations,
  getters
}
